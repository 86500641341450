"use strict";

// Navigation- Mobile
const navBtn = document.querySelector(".menuiconbar");
const menuIcon = document.querySelector(".menuicon");
const mobileNav = document.querySelector(".mobile-nav");
const mobileNavList = document.querySelector(".mobile-nav-list");
const headerDoc = document.querySelector(".header");

// State
let state = "closed";
navBtn.addEventListener("click", function () {
  if (state === "closed") {
    headerDoc.style.height = "25rem";
    headerDoc.classList.toggle("header-mob");
    menuIcon.classList.toggle("menuicon-open");
    setTimeout(function () {
      mobileNav.style.display = "flex";
      mobileNavList.style.display = "flex";
    }, 600);
    state = "open";
  } else if (state === "open") {
    headerDoc.classList.toggle("header-mob");
    menuIcon.classList.toggle("menuicon-open");
    mobileNav.style.display = "none";
    mobileNavList.style.display = "none";
    setTimeout(function () {
      headerDoc.style.height = "9.6rem";
    }, 200);
    state = "closed";
  }
});

//////////////////////////////////////////
// Smooth Scrolling
/////////////////////////////////////////
const eventList = function (elname, secName) {
  const el = document.querySelectorAll(`${elname}`);
  const section = document.getElementById(secName);
  el.forEach((elem) =>
    elem.addEventListener("click", function () {
      console.log(section);
      section.scrollIntoView({ behavior: "smooth" });
    })
  );
};
eventList(".about", "how");
eventList(".services", "how");
eventList(".testimonials", "how");
eventList(".plans", "how");
eventList(".contact", "how");

// imports
import "../node_modules/core-js/stable";
import "regenerator-runtime/runtime";
